import './App.css';

function NavBarButton({image_source, text}) {
  return (
    <div>
      <div className="navButton">{text} <img className="navButtonIcon" src={image_source} /></div>
    </div>
  )
}

function NavBar(){
  return (
    <div className="topbar">

      <div className="liliovali"><img className="liliovaliIcon" src="kirbybackground2.png" /></div>

      <div className="navbargrid">

        <NavBarButton text={"Games"} image_source={"kirbybackground2.png"} />
        <NavBarButton text={"Assets"} image_source={"kirbybackground2.png"} />
        <NavBarButton text={"Commissions"} image_source={"kirbybackground2.png"} />
        <NavBarButton text={"Contact"} image_source={"kirbybackground2.png"} />
        <NavBarButton text={"Shop"} image_source={"kirbybackground2.png"} />

      </div>

      <div className="contacts">
        <a href="https://www.instagram.com/liliovali/" target="_blank"><img className="contactsImage" src="instagram_icon.webp" /></a>
        <a href="default.asp" target="_blank"><img className="contactsImage" src="instagram_icon.webp" /></a>
        <a href="default.asp" target="_blank"><img className="contactsImage" src="instagram_icon.webp" /></a>
      </div>

    </div>
  );
}

function ShowcaseButton({image_source, text}) {
  return (
    <div className="showcaseImageBox"><img className="showcasePlaceholder" src={image_source} /></div>
  );
}


function ShowcaseBlock() {
  return (
    <span>
      <div className="showcaseBlock">
      <div className="showcaseImageBoxLarge"><img className="showcasePlaceholder" src={"kirbybackground2.png"} /></div>
        {Array.from(Array(8 * 4 - 4).keys()).map(x =>
          <ShowcaseButton image_source={"kirbybackground2.png"} />
        )}
      </div>
    </span>
  )
}

function Showcase(){
  return (
    <div className="showcaseGrid">
      <div className="m-scroll">
        <ShowcaseBlock />
        <ShowcaseBlock />     
      </div>
    </div>
  );
}


function App() {
  return (
    <div className="App">


      <NavBar />

      <div className="Content">
        <header>
          <p className="welcomeText">Welcome !</p>
        </header>

        <Showcase />
      </div>
    </div>
  );
}

export default App;
